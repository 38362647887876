import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'libraryTemplatesMessageCategoryType'
})
export class LibraryTemplatesMessageCategoryTypePipe implements PipeTransform {

  private readonly categoryTranslations: { [key: string]: string } = {
    IMAGE: 'Immagine',
    VIDEO: 'Video',
    DOCUMENT: 'Documento',
    OTHER: 'Altro'
  };

  transform(value: string): string {
    return this.categoryTranslations[value] || value;
  }

}
