import { Component, Inject, OnInit, Optional } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Store, select } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { Client } from "src/app/commons/models/client.model";
import { Session, SessionType } from "src/app/commons/models/session.model";
import { User } from "src/app/commons/models/user.model";
import { AlertService } from "src/app/commons/services/alert.service";
import * as ClientActions from "src/app/store/actions/client.actions";
import { AppState } from "src/app/store/reducers";
import * as AuthSelectors from "src/app/store/selectors/auth.selectors";

@Component({
  selector: "app-client-edit",
  templateUrl: "./client-edit.component.html",
  styleUrls: ["./client-edit.component.scss"],
})
export class ClientEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _client: Client;
  currentUser: User;
  tipologie: SessionType[] = [/*'local',*/ "send_app", "socket", "official"];

  clientForm: UntypedFormGroup;
  sessionForm: UntypedFormGroup;
  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: UntypedFormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.store$
      .pipe(
        select(AuthSelectors.getCurrentUser),
        takeUntil(this.unsubscribe$),
        map((dto) => (dto ? new User(dto) : null))
      )
      .subscribe((user) => (this.currentUser = user));
  }

  ngOnInit() {
    if (this.data) {
      this.client = this.data.client;
    }

    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let clientGroup = {
      nome: ["", Validators.required],
      telefono: ["", Validators.required],
      userId: ["", Validators.required],
    };

    let sessionGroup = {
      tipologia: ["", Validators.required],
      accessToken: [""],
      instanceId: [""],
      waNumId: [""],
      waToken: [""],
      waAppId: [""],
    };

    this.clientForm = this.fb.group(clientGroup);
    this.sessionForm = this.fb.group(sessionGroup);

    this.sessionForm
      .get("tipologia")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data === "send_app") {
          this.sessionForm
            .get("accessToken")
            .setValidators([Validators.required]);
          this.sessionForm
            .get("instanceId")
            .setValidators([Validators.required]);
        } else {
          this.sessionForm.get("accessToken").clearValidators();
          this.sessionForm.get("instanceId").clearValidators();
        }

        this.sessionForm.get("accessToken").updateValueAndValidity();
        this.sessionForm.get("instanceId").updateValueAndValidity();

        if (data === "official") {
          this.sessionForm
            .get("waNumId")
            .setValidators([Validators.required]);
          this.sessionForm
            .get("waToken")
            .setValidators([Validators.required]);
          this.sessionForm
            .get("waAppId")
            .setValidators([Validators.required]);
        } else {
          this.sessionForm.get("waNumId").clearValidators();
          this.sessionForm.get("waToken").clearValidators();
          this.sessionForm.get("waAppId").clearValidators();
        }

        this.sessionForm.get("waNumId").updateValueAndValidity();
        this.sessionForm.get("waToken").updateValueAndValidity();
      });
  }

  get isSendApp(): boolean {
    return (
      this.session?.tipologia === "send_app" ||
      this.sessionForm.value.tipologia === "send_app"
    );
  }

  get isOfficial(): boolean {
    return (
      this.session?.tipologia === "official" ||
      this.sessionForm.value.tipologia === "official"
    );
  }

  private ngOnChanges() {
    if (this.clientForm && this.sessionForm) {
      this.clientForm.reset();
      this.sessionForm.reset();

      if (this.client && this.session) {
        this._initFormValues(this.client, this.session);
      } else {
        this._initFormValues();
      }
    }
  }

  private _initFormValues(client?: Client, session?: Session): void {
    this.clientForm.patchValue({
      userId: this.currentUser.id,
    });
    if (client) {
      this.clientForm.patchValue({
        nome: client.nome,
        telefono: client.telefono,
        user: client.user,
      });
    }

    if (session) {
      this.sessionForm.patchValue({
        tipologia: session.tipologia,
        accessToken: session?.accessToken,
        instanceId: session?.instanceId,
        waNumId: session?.waNumId,
        waToken: session?.waToken,
        waAppId: session?.waAppId,
      });

      // this.sessionForm.get('tipologia').disable();
    }
  }

  get client(): Client {
    return this._client;
  }

  get session(): Session {
    return this._client?.sessions[0];
  }

  set client(client: Client) {
    this._client = client;
    this.ngOnChanges();
  }

  private _prepareSaveClient(): [Client, Session] {
    let savingClient: Client = Client.fromFormGroup(
      this.clientForm,
      this.client
    );
    let savingSession: Session = Session.fromFormGroup(
      this.sessionForm,
      this.session
    );
    return [savingClient, savingSession];
  }

  save() {
    let [unsavedClient, unsavedSession] = this._prepareSaveClient();
    this.store$.dispatch(
      ClientActions.saveClient({
        client: unsavedClient,
        session: unsavedSession,
      })
    );
  }

  close() {
    if (this.clientForm.pristine) {
      this.store$.dispatch(ClientActions.closeClientDialog());
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe((result) => {
          if (result) {
            this.store$.dispatch(ClientActions.closeClientDialog());
          }
        });
    }
  }

  deleteClient() {
    if (this.client) {
      this.store$.dispatch(ClientActions.deleteClient({ client: this.client }));
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
