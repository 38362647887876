/* eslint-disable @ngrx/on-function-explicit-return-type */
import { Action, createReducer, on } from '@ngrx/store';
import { ClientDTO } from 'src/app/commons/models/client.model';

import * as ClientActions from '../actions/client.actions';
import * as LibraryTemplatesMessageActions from '../actions/library-templates-messages.actions';
import { LibraryTemplatesMessageDTO, LibraryTemplatesMessageFilters } from 'src/app/commons/models/library-templates-message.model';

export interface LibraryTemplatesMessageState {
  list: LibraryTemplatesMessageDTO[],
  total: number,
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: LibraryTemplatesMessageFilters,
  dialogId: string,
  libraryTemplateMessage: LibraryTemplatesMessageDTO,
  sessionId: number,
  clients: ClientDTO[]
};

const initialState: LibraryTemplatesMessageState = {
  list: [],
  total: 0,
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  libraryTemplateMessage: null,
  sessionId: null,
  clients: null
};

const libraryTemplatesMessageReducer = createReducer(
  initialState,
  on(ClientActions.loadClientCompleted, (state, { client }) => {
    return { ...state, sessionId: client?.sessions?.length > 0 ? client.sessions[0]?.id : null };
  }),
  on(LibraryTemplatesMessageActions.loadLibraryTemplatesMessageCompleted, (state, { libraryTemplatesMessage, currentPage, total, perPage, order, direction, filters }) => {
    console.log('Reducer updating state with:', { libraryTemplatesMessage, currentPage, total, perPage, order, direction, filters });
    return {
      ...state,
      list: libraryTemplatesMessage,
      currentPage,
      total,
      perPage,
      order,
      direction,
      filters
    };
  }),
  on(LibraryTemplatesMessageActions.saveLibraryTemplateMessageCompleted, (state, { libraryTemplateMessage }) => {
    return { ...state, libraryTemplateMessage };
  }),
  on(LibraryTemplatesMessageActions.libraryTemplateMessageDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(LibraryTemplatesMessageActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(LibraryTemplatesMessageActions.loadLibraryTemplateMessageCompleted, (state, { libraryTemplateMessage }) => {
    return { ...state, libraryTemplateMessage };
  }),
  on(LibraryTemplatesMessageActions.saveLibraryTemplateMessageCompleted, (state, { libraryTemplateMessage }) => {
    return { ...state, libraryTemplateMessage };
  }),
  on(LibraryTemplatesMessageActions.selectLibraryTemplateMessage, (state, { libraryTemplateMessage }) => {
    return { ...state, libraryTemplateMessage: libraryTemplateMessage, sessionId: libraryTemplateMessage.session_id };
  }),
);

export function reducer(state: LibraryTemplatesMessageState | undefined, action: Action) {
  return libraryTemplatesMessageReducer(state, action);
}
