import { createAction, props } from '@ngrx/store';
import {
  CategoryType,
  LibraryTemplatesMessageDTO,
  LibraryTemplatesMessageFilters,
} from 'src/app/commons/models/library-templates-message.model';

export const loadAllForCategory = createAction('[LibraryTemplatesMessage] Load all for category', props<{ fileCategory: CategoryType }>());

export const loadLibraryTemplatesMessage = createAction('[LibraryTemplatesMessage] Load Library templates message', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: LibraryTemplatesMessageFilters }>());
export const loadLibraryTemplatesMessageCompleted = createAction('[LibraryTemplatesMessage] Load Library templates message Completed', props<{ libraryTemplatesMessage: LibraryTemplatesMessageDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: LibraryTemplatesMessageFilters }>());
export const loadLibraryTemplatesMessageFailed = createAction('[LibraryTemplatesMessage] Load Library templates message Failed', props<{ error: any }>());

export const loadLibraryTemplateMessage = createAction('[LibraryTemplateMessage] Load Library template message', props<{ id: number }>());
export const loadLibraryTemplateMessageCompleted = createAction('[LibraryTemplateMessage] Load Library template message Completed', props<{ libraryTemplateMessage: LibraryTemplatesMessageDTO }>());
export const loadLibraryTemplateMessageFailed = createAction('[LibraryTemplateMessage] Load Library template message Failed', props<{ error: any }>());

export const changePage = createAction('[LibraryTemplatesMessage] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[LibraryTemplatesMessage] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[LibraryTemplatesMessage] Change filters', props<{ filters: LibraryTemplatesMessageFilters }>());

export const editLibraryTemplateMessage = createAction('[LibraryTemplateMessage] Edit Library template message', props<{ libraryTemplateMessage: LibraryTemplatesMessageDTO }>());
export const selectLibraryTemplateMessage = createAction('[LibraryTemplateMessage] Select Library TemplateMessage', props<{ libraryTemplateMessage: LibraryTemplatesMessageDTO }>());
export const setLibraryTemplateMessage = createAction('[LibraryTemplateMessage] Set Library TemplateMessage', props<{ libraryTemplateMessage: LibraryTemplatesMessageDTO }>());

export const libraryTemplateMessageDialogOpened = createAction('[LibraryTemplateMessage] Detail dialog opened', props<{ dialogId: string }>());
export const closeLibraryTemplateMessageDialog = createAction('[LibraryTemplateMessage] Close detail dialog');

export const sendLibraryTemplateMessage = createAction('[LibraryTemplateMessage] Send Library templateMessage', props<{ libraryTemplateMessage: LibraryTemplatesMessageDTO }>());
export const sendLibraryTemplateMessageCompleted = createAction('[LibraryTemplateMessage] Send Library templateMessage completed', props<{ libraryTemplateMessage: LibraryTemplatesMessageDTO }>());
export const sendLibraryTemplateMessageFailed = createAction('[LibraryTemplateMessage] Send Library templateMessage failed', props<{ error: any }>());
export const sendLibraryTemplateMessageCancelled = createAction('[LibraryTemplateMessage] Send Library templateMessage cancelled');

export const saveLibraryTemplateMessage = createAction('[LibraryTemplateMessage] Save Library TemplateMessage', props<{ libraryTemplateMessage: LibraryTemplatesMessageDTO }>());
export const saveLibraryTemplateMessageCompleted = createAction('[LibraryTemplateMessage] Save Library TemplateMessage completed', props<{ libraryTemplateMessage: LibraryTemplatesMessageDTO }>());
export const saveLibraryTemplateMessageFailed = createAction('[LibraryTemplateMessage] Save Library TemplateMessage failed', props<{ error: any }>());

export const deleteLibraryTemplateMessage = createAction('[LibraryTemplateMessage] Delete Library TemplateMessage', props<{ libraryTemplateMessage: LibraryTemplatesMessageDTO }>());
export const deleteLibraryTemplateMessageCompleted = createAction('[LibraryTemplateMessage] Delete Library TemplateMessage completed', props<{ libraryTemplateMessage: LibraryTemplatesMessageDTO }>());
export const deleteLibraryTemplateMessageCancelled = createAction('[LibraryTemplateMessage] Delete Library TemplateMessage cancelled');
export const deleteLibraryTemplateMessageFailed = createAction('[LibraryTemplateMessage] Delete Library TemplateMessage failed', props<{ error: any }>());

export const backToClient = createAction('[TemplateMessage] Back to client', props<{ clientId: number }>());
