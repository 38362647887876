<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="client">Modifica cliente: {{ client.nome }}</span>
    <span *ngIf="!client">Nuovo cliente</span>
  </span>
  <span *ngIf="client">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteClient()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="clientForm" novalidate>
    <mat-form-field class="u-full-width">
      <input
        matInput
        placeholder="Nome"
        formControlName="nome"
        type="text"
        required
      />
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input
        matInput
        placeholder="Telefono"
        formControlName="telefono"
        type="text"
        required
      />
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>

    <div class="formGroup__wrapper">
      <form [formGroup]="sessionForm" novalidate>
        <mat-label class="formGroup__label"> Sessione </mat-label>
        <mat-form-field class="formGroup u-group-full-width">
          <mat-select
            formControlName="tipologia"
            placeholder="Tipologia"
            required
          >
            <mat-option *ngFor="let tipologia of tipologie" [value]="tipologia">
              {{ tipologia | sessionType }}
            </mat-option>
          </mat-select>
          <mat-error>Campo obbligatorio</mat-error>
        </mat-form-field>

        <div [hidden]="!isSendApp">
          <mat-form-field class="formGroup u-group-full-width">
            <input
              matInput
              placeholder="Access Token"
              formControlName="accessToken"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="formGroup u-group-full-width">
            <input
              matInput
              placeholder="Instance Id"
              formControlName="instanceId"
              type="text"
            />
          </mat-form-field>
        </div>

        <div [hidden]="!isOfficial">
          <mat-form-field class="formGroup u-group-full-width">
            <input
              matInput
              placeholder="ID dell' account WhatsApp Business:"
              formControlName="waNumId"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="formGroup u-group-full-width">
            <input
              matInput
              placeholder="Token d'accesso"
              formControlName="waToken"
              type="text"
            />
          </mat-form-field>

          <mat-form-field class="formGroup u-group-full-width">
            <input
              matInput
              placeholder="ID dell' app"
              formControlName="waAppId"
              type="text"
            />
          </mat-form-field>
        </div>
      </form>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="clientForm.pristine && sessionForm.pristine"
    >
      Reset
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="
        !(
          (!clientForm.pristine || !sessionForm.pristine) &&
          clientForm.valid &&
          sessionForm.valid
        )
      "
    >
      Save
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Chiudi
  </button>
</mat-dialog-actions>
