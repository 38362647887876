import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { MessageDTO, MessageFilters, SingleMessageDTO } from '../../models/message.model';


@Injectable({
  providedIn: "root"
})
export class LaravelMessageService {

  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/messages",
      show: environment.baseUrl + "/api/message",
      cancel: environment.baseUrl + "/api/message/cancel",
      cancels: environment.baseUrl + "/api/messages/cancel",
      reschedule: environment.baseUrl + "/api/message/reschedule",
      reschedules: environment.baseUrl + "/api/messages/reschedule",
      sendSingle: environment.baseUrl + "/api/messages/send",
      export: environment.baseUrl + "/api/messages/export",
    };
  }

  private getParams(template_id?: number,
    page?: number,
    per_page?: number,
    order?: string,
    direction?: string,
    filters?: MessageFilters) {
    let params = {};
    if (template_id) params["template_id"] = "" + template_id;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (filters) {
      if (filters.search) params["search"] = "" + filters.search;
      if (filters.invioMassivo != undefined && filters.invioMassivo != null) {
        params["invio_massivo"] = "" + filters.invioMassivo;
      }
      if (filters.phone) params["phone"] = "" + filters.phone;
      if (filters.sequenceId) params["sequence_id"] = "" + filters.sequenceId;
      if (filters.ids) params["ids[]"] = filters.ids;
      if (filters.statuses) params["statuses[]"] = filters.statuses;
      if (filters.types) params["types[]"] = filters.types;
      if (filters.createdAtFrom) params["created_at_from"] = "" + formatDateForBackend(filters.createdAtFrom, true);
      if (filters.createdAtTo) params["created_at_to"] = "" + formatDateForBackend(filters.createdAtTo, true);
      if (filters.sentAtFrom) params["sent_at_from"] = "" + formatDateForBackend(filters.sentAtFrom, true);
      if (filters.sentAtTo) params["sent_at_to"] = "" + formatDateForBackend(filters.sentAtTo, true);
      if (filters.clientId) params["client_id"] = "" + filters.clientId;
      if (filters.sessionId) params["session_id"] = "" + filters.sessionId;
      if (filters.failReason) params["fail_reason"] = filters.failReason;
      if (filters.externalId) params["external_id"] = filters.externalId;
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
    }

    return params;
  }

  public list(
    template_id?: number,
    page?: number,
    per_page?: number,
    order?: string,
    direction?: string,
    filters?: MessageFilters,
  ): Observable<ListResultDTO<MessageDTO>> {
    let params = this.getParams(template_id, page, per_page, order, direction, filters);
    if (per_page) {
      return this.httpClient.get<ListResultDTO<MessageDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<MessageDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }

  public export(
    template_id?: number,
    filters?: MessageFilters,
  ): Observable<Blob> {
    let params = this.getParams(template_id, null, null, null, null, filters);
    return this.httpClient.get(this.ROUTES.export, {
      params: new HttpParams({
        fromObject: params,
      }),
      responseType: "blob",
    });
  }

  public show(id: number): Observable<MessageDTO> {
    let params = { id: "" + id };
    return this.httpClient.get<MessageDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public cancel(id: number, entireSequence: boolean = false): Observable<{ id: string[], count: number }> {
    return this.httpClient.post<{ id: string[], count: number }>(`${this.ROUTES.cancel}/${id}`, {
      entire_sequence: entireSequence
    });
  }

  public reschedule(id: number, date: Date): Observable<MessageDTO> {
    return this.httpClient.post<MessageDTO>(`${this.ROUTES.reschedule}/${id}`, {
      date: formatDateForBackend(date, true)
    });
  }

  public reschedules(ids: number[], date: Date): Observable<{ id: string[], count: number }> {
    return this.httpClient.post<{ id: string[], count: number }>(this.ROUTES.reschedules, {
      messages: ids,
      date: formatDateForBackend(date, true)
    });
  }

  public cancels(ids: number[]): Observable<{ id: string[], count: number }> {
    return this.httpClient.post<{ id: string[], count: number }>(this.ROUTES.cancels, {
      messages: ids
    });
  }

  public sendSingle(message: SingleMessageDTO, sessionId: number): Observable<string> {
    return this.httpClient.post<string>(`${this.ROUTES.sendSingle}/${sessionId}`, message);
  }
}
