import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'libraryTemplatesMessageStatusType'
})
export class LibraryTemplatesMessageStatusTypePipe implements PipeTransform {

  private readonly statusTranslations: { [key: string]: string } = {
    APPROVED: 'Approvato',
    ERROR: 'Errore',
    PENDING: 'In Attesa'
  };

  transform(value: string): string {
    return this.statusTranslations[value] || value;
  }

}
