import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Actions, createEffect, ofType, concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelClientService } from '../../commons/services/backend/laravel-client.service';
import { ClientEditComponent } from '../../modules/home/clients/client-edit/client-edit.component';
import * as ClientActions from '../actions/client.actions';
import * as SessionActions from '../actions/session.actions';
import * as RouterActions from '../actions/router.actions';
import { AppState } from '../reducers';
import { getClient, getClientDialogId, getClientsTableState } from '../selectors/client.selectors';


@Injectable()
export class ClientEffects {

  error$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.saveClientFailed, ClientActions.deleteClientFailed, ClientActions.loadClientsFailed, ClientActions.loadClientFailed, ClientActions.setRecivedMessagesHookFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore:', error);
        }
      })
    )
  }, { dispatch: false }
  );

  loadClients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.loadClients),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.clientService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              ClientActions.loadClientsCompleted({ clients: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(ClientActions.loadClientsFailed({ error }))
            })
          )
      })
    )
  }
  );

  loadClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.loadClient),
      switchMap(({ id }) => {
        return this.clientService.show(id)
          .pipe(
            map(result =>
              ClientActions.loadClientCompleted({ client: result })
            ),
            catchError(error => {
              return of(ClientActions.loadClientFailed({ error }))
            })
          )
      })
    )
  }
  );

  // loadClientCompleted$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ClientActions.loadClientCompleted),
  //     map((client) => ClientActions.selectClient(client))
  //   )
  // );

  changePage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.changePage),
      concatLatestFrom(() => this.store$.select(getClientsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => ClientActions.loadClients({ page: page, perPage: size, order, direction, filters, includes }))
    )
  }
  );

  changeSort$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.changeSort),
      concatLatestFrom(() => this.store$.select(getClientsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => ClientActions.loadClients({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  }
  );

  changeFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.changeFilters),
      concatLatestFrom(() => this.store$.select(getClientsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => ClientActions.loadClients({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  }
  );

  editClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.editClient),
      map(({ client }) => {
        let dialogRef = this.dialog.open(ClientEditComponent, {
          data: {
            client
          }
        });
        return ClientActions.clientDialogOpened({ dialogId: dialogRef.id });
      }))
  }
  );

  saveClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.saveClient),
      mergeMap(({ client, session }) =>
        this.clientService.upsert(client.toDTO(), session?.toDTO())
          .pipe(
            map(result =>
              ClientActions.saveClientCompleted({ client: result })
            ),
            catchError(error => of(ClientActions.saveClientFailed({ error })))
          )
      )
    )
  }
  );

  onSaveCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.saveClientCompleted),
      map(action => action.client),
      tap(client => this.alertService.showConfirmMessage(`${client.nome} salvata con successo`)),
      map(() => ClientActions.closeClientDialog())
    )
  }
  );


  deleteClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.deleteClient),
      switchMap(({ client }) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare il cliente ${client.nome}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.clientService.delete(client.id)
                  .pipe(
                    map(() => ClientActions.deleteClientCompleted({ client: client.toDTO() })),
                    catchError(error => of(ClientActions.deleteClientFailed({ error })))
                  )

                : of(ClientActions.deleteClientCancelled());
            })
          )
      )
    )
  }
  );

  onDeleteCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.deleteClientCompleted),
      tap(({ client }) => this.alertService.showConfirmMessage(`Cliente ${client.nome} eliminato con successo`)),
      map(() => ClientActions.closeClientDialog()),
    )
  }
  );

  closeDialog = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.closeClientDialog),
      concatLatestFrom(() => this.store$.select(getClientDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    )
  }, { dispatch: false }
  );

  reloadAfterSaveOrDelete = createEffect(() => {
      return this.actions$.pipe(
        ofType(ClientActions.saveClientCompleted, ClientActions.deleteClientCompleted, SessionActions.statusAllCompleted),
        concatLatestFrom(() => this.store$.select(getClientsTableState)),
        map(([_, { currentPage, perPage, direction, order, filters, includes }]) => ClientActions.loadClients({ page: currentPage, perPage, order, direction, filters, includes })),
      )
    }
  );

  // after delete if current client is the deleted client
  // then navigate to the previous page
  goBackAfterDelete = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.deleteClientCompleted),
      concatLatestFrom(() => this.store$.select(getClient)),
      filter(([{ client }, currentClient]) => client.id === currentClient.id),
      map(() => RouterActions.routerGo({ path: ['/'] })),
    )
  }
  );

  selectClient = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.selectClient),
      map(({ client }) => RouterActions.routerGo({ path: ['/client/', client.id] })),
    )
  }
  );

  setRecivedMessagesHook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.setRecivedMessagesHook),
      mergeMap(({ id, hook }) =>
        this.clientService.setRecivedMessagesHook(id, hook)
          .pipe(
            map(result =>
              ClientActions.setRecivedMessagesHookCompleted({ client: result })
            ),
            catchError(error => of(ClientActions.setRecivedMessagesHookFailed({ error })))
          )
      )
    )
  }
  );

  setRecivedMessagesHookCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ClientActions.setRecivedMessagesHookCompleted),
      map(action => action.client),
      tap(() => this.alertService.showConfirmMessage(`Hook per l'esporazione delle chat impostato con successo`)),
      map(() => ClientActions.closeClientDialog())
    )
  }
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private clientService: LaravelClientService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}
